@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.modalAllReservationContainer {
  h2 {
    @include font-level-5;
    margin: 0px 0 16px 0;    
    @include respond(medium) {
      @include font-level-6;
    }
  }
  h4 {
    @include font-level-8;
    text-align: center;
    margin: 0;
    @include respond(medium) {
      @include font-level-9;
    }
  }
}