@import "../../../styles/theme-styles.scss";

.modalAllReservationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .containerButtons {
    @include containerButtons;
    display: flex;
    justify-content: center;
  }

  h2 {
    @include font-level-7;
    font-weight: bold;
    text-align: center;
  }
  p {
    @include font-level-11;
  }
}
