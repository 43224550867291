@import "./styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}


@font-face {
  font-family: "HelveticBold";
  src: url("../public/font/HelveticaNeueLTStd-Bd.otf");
}
@font-face {
  font-family: "Helvetic_Black";
  src: url("../public/font/HelveticaNeueLTStd-Blk.otf");
}
@font-face {
  font-family: "Helvetic_Medium";
  src: url("../public/font/HelveticaNeueLTStd-Md.otf");
}
@font-face {
  font-family: "Helvetic_Light";
  src: url("../public/font/HelveticaNeueLTStd-Lt.otf");
}

.App {
  display: flex;
  justify-content: center;
  font-family: "Helvetic_Medium";
  color: $gray-1;
  font-weight: 400, Regular; 
  letter-spacing: 1px;
}

.containerApp{
  width: 90%;
  padding: 30px 0 30px 0;
  .snackbarSuccess {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 90%;
    z-index: 999;
    background-color: $orange;
    border-radius: 4px;
    width: 50%;
    height: 35px;
    transform: translate(-50%, -50%);
    border-radius: 0px 0px 4px 4px;
    span {
      @include font-level-6;
      color: white;
    }
    @include respond(medium) {
      span {
        @include font-level-9;
        color: white;
      }
    }
  }
  .snackbarFail {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 50%;
    top: 88%;
    z-index: 10;
    background-color: #e9bfbf;
    border-radius: 4px;
    padding: 12px;
    transform: translate(-50%, -50%);
    span {
      @include font-level-6;
      color: $red-2;
    }
    @include respond(medium) {
      span {
        @include font-level-10;
        color: $red-2;
      }
    }
  }
}
.errorInput {
  color: $red-2 !important;
  width: auto;
  @include font-level-9;
}

body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: $gray-2;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: $gray-3;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid $gray-2;  /* creates padding around scroll thumb */
}