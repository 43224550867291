@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.grilla {
  margin: $margin-base 0 ;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px;
  gap: 1px;
  background-color: #efecec;
  border: 1px solid #efecec;
  box-sizing: border-box;
  overflow-x: hidden;
  &-header {
    height: 60px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  &-preview-active {
    cursor: inherit !important;
  }

  &-card-day {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 120px;
    width: calc(20% - 0.8px);
    background-color: white;
    .messageTransport {
      border: 0.1px solid $gray-2;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 75px;
      img {
        padding-right: 4px;
      }
    }
    div {
      margin: 0 8px 0 8px;
      @include font-level-6;
      font-weight: 100;
    }
    span {
      // font-size: 16px !important;
      @include font-level-6;
      font-weight: 100;
      width: 100%;
    }
    .textDesktop {
      @include respond(medium) {
        display: none;
      }
    }
  }
  &-without-date {
    background-color: $gray-2 !important;
    .messageTransport {
      cursor: text;
      border-radius: 8px;
      background: $white;
      @include font-level-8;
      padding: 5px;
      @include respond(medium) {
        background: none;
      }
      span {
        @include font-level-8;
        margin-left: 0;
        color: $gray-3 !important;
        font-weight: bold !important;
        text-align: center;
      }
      .textDesktop {
        @include respond(medium) {
          display: none;
        }
      }
      &.hasBooking {
        cursor: pointer;
        border-radius: 8px;
        background: $green-1;
        @include font-level-8;
        padding: 5px;
        @include respond(medium) {
          background: none;
        }
        span {
          @include font-level-8;
          margin-left: 0;
          color: $green-2 !important;
          font-weight: bold !important;
          text-align: center;
        }
        .textDesktop {
          width: auto;
          @include respond(medium) {
            display: none;
          }
        }
      }
    }
  }
  &-success {
    cursor: pointer;
    .messageTransport {
      cursor: pointer;
      border-radius: 8px;
      background: $green-1;
      @include font-level-8;
      padding: 5px;
      @include respond(medium) {
        background: none;
      }
      span {
        @include font-level-8;
        margin-left: 0;
        color: $green-2 !important;
        font-weight: bold !important;
      }
      .textDesktop {
        @include respond(medium) {
          display: none;
        }
      }
    }
  }
  &-preview-success {
    background: $green-3;
    .messageTransport {
      cursor: pointer;
      border-radius: 8px;
      background: $green-1;
      @include font-level-8;
      padding: 5px;
      span {
        @include font-level-8;
        margin-left: 0;
        color: $green-2 !important;
        font-weight: bold !important;
      }
      @include respond(medium) {
        background: none;
        span {
          font-size: $margin-milli;
          text-align: center;
        }
      }
    }
  }
  &-preview-fail {
    background: $red-3;
    .messageTransport {
      cursor: text;
      border-radius: 8px;
      background: $white;
      @include font-level-8;
      padding: 5px;
      display: flex;
      flex-direction: column;
      top: 45%;
      img {
        display: none;
      }
      span {
        @include font-level-8;
        margin-left: 0;
        color: $red-2 !important;
        font-weight: bold !important;
      }
      p {
        font-weight: bold !important;
        @include font-level-8;
        cursor: pointer;
      }
      @include respond(medium) {
        background: none;
        span {
          font-size: $margin-milli;
          text-align: center;
        }
      }
    }
    .modifyButton {
      @include font-level-7;
      color: #585757;
      font-weight: bold;
      border: none;
      outline: none;
      padding: $margin-milli;
      position: absolute;
      top: 60%;
      @include respond(medium) {
        
        @include font-level-11;
      }
    }
  }
}
