@import '../../../styles/theme-styles.scss';

button{
  @include button-default;
  background-color: transparent;
  border-radius: 4px;
  // width: auto;
  padding: 16px;
  color: white;
  letter-spacing: 0.02rem;
  &:disabled{
    background-color: $gray-3;
  }
  &:focus{
    outline: 1px solid $orange;
  }
  &:active{
    outline: 3px solid $orange;
  }
  &:hover {
    // transform: scale(1.05);
  }
}