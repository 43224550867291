@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.modalReservationContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  h4 {
    @include font-level-9;
    margin: calc(8px + 15 * (100vw - 1200px) / 2640) 0 calc(8px + 15 * (100vw - 1200px) / 2640) 3px
  }
  h3 {
    @include font-level-10;
    margin: 0px 0 16px 0;
  }
  h2 {
    @include font-level-5;
    margin: $margin-milli 0 $margin-base 0;
  }
  span {
    color: $red-fail !important;
    @include font-level-9;
  }
  p {
    @include font-level-8;
    @include respond(medium) {
      font-size: 8px;
    }
  }
  .dataUser,
  .stopPoint {
    width: 100%;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    .header {
      display: flex;
      align-items: center;
      flex-direction: revert;
      justify-content: space-between;
      button {
        @include btn-orange;
        font-size: 12px;
        margin-bottom: 0 !important;
      }
    }
    .containerInputs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      @include respond(medium) {
        flex-direction: column;
      }
      .wrapInputs {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-bottom: $margin-base;
        @include respond(medium) {
          width: 100%;
        }
        p {
          @include font-level-11;
          margin: 0;
        }
      }
    }
  }
  .colReservationContainer {
    width: 100%;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    min-height: 30%;
    label {
      margin-bottom: $margin-small;
      @include font-level-8;
    }
    .timeAvailable {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $margin-small;
      input {
        width: 15px;
        margin: 0 8px 0 0;
      }
      label,
      img {
        margin: 0 $margin-milli 0 0;
      }
    }
    .timeNoAvailable {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $margin-small;
      label,
      img {
        margin: 0 $margin-milli 0 0;
        color: $gray-disabled !important;
      }
    }
  }
  .recurrency {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .wrapRecurrency {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include respond(medium) {
        flex-direction: column;
        align-items: flex-start;
      }
      div {
        width: 47%;
        display: flex;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        @include respond(medium) {
          width: 100%;
        }
        .listOfDays {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .wrapDays{
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            input {
              margin-right: 4px;
            }
            label {
              margin-right: 8px;
            }
            @include respond(medium) {
              margin-top: $margin-small;
            }
          }
        }
      }
    }
  }
  .containerButtons {
    @include containerButtons;
    width: 100%;
    @include respond(medium) {
      justify-content: center;
      flex-direction: column-reverse;
      button {
        margin-left: 0px !important;
        margin: 4px 0;
      }
    }
  }
  input,
  select {
    @include default-input;
    border-radius: 4px;
    color: $gray-1;
  }
  label {
    @include font-level-9;
    margin: 0;
    display: flex;
    align-items: center;
    input {
      margin: 0 $margin-milli 0 0;
    }
    div {
      padding: 8px 0 8px 0;
    }
    h1 {
      @include font-level-6;
    }
  }
}

.modal::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.modal::-webkit-scrollbar-track {
  background: $gray-2; /* color of the tracking area */
}

.modal::-webkit-scrollbar-thumb {
  background-color: $gray-3; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid $gray-2; /* creates padding around scroll thumb */
}
