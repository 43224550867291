@import "../../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.myReservations {
  width: 100%;
  margin-top: 16px;
  border-top: .6px solid #ccc;
  .reservationInfo{
    padding-top: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    @include respond(medium) {
      align-items: flex-start;
      flex-direction: column;
    } 
    div {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      @include respond(medium) {
        width: 100%;
      } 
    }
    span {
      @include font-level-8;
      margin-bottom: 8px;
      @include respond(medium) {
        @include font-level-10;
      } 
    }
  }
  button {
    @include btn-orange;
    padding: 0 !important;
    @include respond(medium) {
      margin-top: 12px;
    } 
  }
  
  p {
    @include font-level-9;
  }
}