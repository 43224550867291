@import "../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.transportContainer {
  position: relative;
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .butonOrange {
      @include btn-orange;
    }
  }
  .number-day {
    position: absolute;
    left: 15px;
    top: 25px;
    @include respond(small) {
      left: 3px;
    }
  }
  .textDescriptionBooking {
    @include font-level-8;
    margin-left: 0;
    font-weight: bold !important;
    color: $gray-3;
    @include respond(medium) {
      display: none;
    }
  }
  .containerButtons {
    @include containerButtons;
    position: sticky;
    right: 12px;
    bottom: 12px;
    margin-left: -16px;
    width: 100%;
    box-shadow: 1px 0px 26px 0px rgba(222,217,217,0.78);
    -webkit-box-shadow: 1px 0px 26px 0px rgba(222,217,217,0.78);
    -moz-box-shadow: 1px 0px 26px 0px rgba(222,217,217,0.78);
    @include respond(medium) {
      justify-content: center;
    }
    .disabled {
      background-color: $gray-2;
      color: $gray-1;
      border: none;
    }
  }
}
