@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.container-times {
  width: 92%;
  margin: 9px 0px 8px 16px;
  display: flex;
  flex-direction: column;
  min-height: 30%;
  label {
    margin-bottom: $margin-small;
    @include font-level-6;
  }
  .timeAvailable {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $margin-small;
    input {
      width: 15px;
      margin: 0 8px 0 0;
    }
    label,
    img {
      margin: 0 $margin-milli 0 0;
    }}
  .timeNoAvailable {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $margin-small;
    label,
    img {
      margin: 0 $margin-milli 0 0;
      color: $gray-disabled !important;
    }
  }
  .containerButtons {
    @include containerButtons;
    @include respond(medium) {
      justify-content: center;
    }
  }
}