@import "../../../styles/theme-styles.scss";

@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 750px) {
      @content;
    }
  }
}

.boxPassager {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0px 0px 8px 0px;
  }
  h2 {
    @include font-level-5;
  }
  
  @include respond(medium) {
    h2 {
      @include font-level-10;
    }
  }
  .containerPassager {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    border: none;
    border-bottom: 1px solid $gray-3;
    cursor: pointer;
    color: $gray-1;
    padding: 16px 0 16px 0;
    @include font-level-7;
    .information {
      width: 32%;
      margin-bottom: 4px;
      padding: 8px 0 8px 0;
      div {
        display: flex;
        justify-content: flex-start;
        width: 98%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        strong {
          margin-right: 6px;
        }
      }
    }
    @include respond(medium) {
      @include font-level-8;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;
      .information {
        width: 100%;
        div {
          justify-content: flex-start;
        }
      }
    }
    &:hover {
      background-color: rgb(240, 248, 255);
      box-shadow: 0px 1px 10px 0px rgb(240, 248, 255);
      -webkit-box-shadow: 0px 1px 10px 0px rgb(240, 248, 255);
      -moz-box-shadow: 0px 1px 10px 0px rgb(240, 248, 255);
    }
  }
}
