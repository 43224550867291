@import "../../styles/theme-styles.scss";
.overlay{
  position: absolute;
  background-color: black;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
}

.modal {
  position: sticky;
  background: $white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0px 15px 15px 15px;
  // max-width: 480px;
  // height: auto !important;
  // border: 3px solid #F2F2F2;
  margin-top: 16px;
  z-index: 17;
  position: relative;
  margin-bottom: 24px;    
  max-height: -webkit-fill-available;
  overflow-y: auto;
  // min-height: 25% !important;
  width: 60%;
  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 16;
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
  }
  .material-icons{
    cursor: pointer;  
    border: none;
    position: sticky;
    left: 98%;
    top: 2%;
    font-weight: bolder;
    padding: 4px;
    @include font-level-5;
    transition: transform .2s;
    &:hover {
      transform: scale(1.3);
    }
  }
}